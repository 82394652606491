<style lang="sass" scoped>
.container-login
  font-size: 12px
</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.text-center
          strong Noitaler
        h6.text-muted.text-center 문구1
        .mb-4

        //- router-link.float-right(to='/new/property').text-primary 새로운 비즈니스 추가
        h5.mb-4: strong 비즈니스 선택

        b-list-group.shadow-sm
          b-list-group-item(to='#')
            strong 제네럴아이디어
            small.float-right.text-muted ID: 1000
          b-list-group-item(to='#')
            strong 빈브라더스
            small.float-right.text-muted ID: 1002
          b-list-group-item(to='#')
            strong 해피로봇 레코드
            small.float-right.text-muted ID: 1003

        .mb-3


        .text-center
          span.text-muted.mr-1 여러 사업장의 고객을 관리하고 계신가요?
          a(href='#').text-primary 새로운 비즈니스 추가

</template>

<script>


export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      form: {},
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
